import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./styles/Reports.sass";
import { SelectInput } from "../Components/Form";
import PanelReport from "../Components/Reports/PanelReport";
import { UserPermissions } from "../enums/UserPermissions";
import Heading from "../Components/Heading/Heading";
import ConstructionFunnelReport from "../Components/Reports/ConstructionFunnelReport";
import checkIfAuthorized from "../utils/permissions";
import SalesMarketReport from "../Components/Reports/SalesMarketReport";
import ClosedOutSalesRepsReport from "../Components/Reports/ClosedOutSalesReps";
import PowerBi from "../Components/Reports/PowerBi";
import InfinityDSR from "../Components/Reports/InfinityDSR";
import PanelReport2 from "../Components/Reports/PanelReport-bi";
import SalesReport from "../Components/Reports/SalesReport";
import SunsetBar from "../Components/SunsetBar/Sunsetbar";

let defaultReport = "";
if (checkIfAuthorized(UserPermissions.solar_panel_report)) {
	defaultReport = "solar-panel-report/state";
} else if (checkIfAuthorized(UserPermissions.construction_funnel_report)) {
	defaultReport = "construction-funnel-report";
}

const Reports = () => {
	const [currentReport, setCurrentReport] = useState<string>(defaultReport);

	let report;

	switch (currentReport) {
		case "solar-panel-report/state":
			report = <PanelReport />;
			break;
		case "construction-funnel-report":
			report = <ConstructionFunnelReport />;
			break;
		case "sales-market-report":
			report = <SalesMarketReport />;
			break;
		case "sales-commission-report/closed-out-sales-reps":
			report = <ClosedOutSalesRepsReport />;
			break;
		case "DSR-report-powerbi":
			report = <PowerBi />;
			break;
		case "infinity-DSR":
			report = <InfinityDSR />;
			break;
		case "sales-agent-report":
			report = <SalesReport />;
			break;
		case "panel-report-bi":
			report = <PanelReport2 />;
			break;
		default:
			report = <></>;
	}

	return <Container>
		<SunsetBar/>
		<Heading>Reports</Heading>
		<SelectInput field={{
			controlId: "report",
			label: "Report",
			dropdownData: [
				{
					key: "solar-panel-report/state",
					value: "solar-panel-report/state",
					label: "Solar Panel Report by State",
					permission: UserPermissions.solar_panel_report
				},
				{
					key: "construction-funnel-report",
					value: "construction-funnel-report",
					label: "Construction Funnel Report",
					permission: UserPermissions.construction_funnel_report
				},
				{
					key: "sales-market-report",
					value: "sales-market-report",
					label: "Sales Market Report",
					permission: UserPermissions.sales_market_report
				},
				{
					key: "sales-commission-report/closed-out-sales-reps",
					value: "sales-commission-report/closed-out-sales-reps",
					label: "Closed Out Sales Reps",
					permission: UserPermissions.admin
				},
				{
					key: "DSR-report-powerbi",
					value: "DSR-report-powerbi",
					label: "Daily Sales Report - PowerBI",
					permission: UserPermissions.powerbi
				},
				{
					key: "infinity-DSR",
					value: "infinity-DSR",
					label: "Infinity DSR",
					permission: UserPermissions.infinityDSR
				},
				{
					key: "panel-report-bi",
					value: "panel-report-bi",
					label: "Panel Report BI",
					permission: UserPermissions.solar_panel_report
				}
				,
				{
					key: "sales-agent-report",
					value: "sales-agent-report",
					label: "Sales Agent Report",
					permission: UserPermissions.powerbi
				}
			],
			value: currentReport,
			onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setCurrentReport(e.target.value),
			containerClasses: "report-select"
		}} />
		{report}
	</Container>;
};

export default Reports;
