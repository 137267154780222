import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";
import Table from "../Components/Table/Table";
import TopBar from "../Components/TopBar/TopBar";
import { ThreeCircles } from "react-loader-spinner";
import WhenAuthorized from "../Components/WhenAuthorized";
import { UserPermissions } from "../enums/UserPermissions";
import "./styles/Leaderboards.sass";
import { Card, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getData, setDateRange, setViewType } from "../redux/actions/leaderboards";
import Toggle from "../Components/Toggle/Toggle";
import Modal from "../Components/Modal/Modal";
import { setRepScorecardModalShown, setRepScorecardData } from "../redux/actions/leaderboards";
import Scorecard from "../Components/Scorecard/Scorecard";
import SunsetBar from "../Components/SunsetBar/Sunsetbar";

const TableWrapper = ({ children, header }: { children: React.ReactNode, header: string }) =>
	<div className="leaderboard-table-container">
		<h3>{header}</h3>
		{children}
	</div>;

const loadingCircles = <div className="mt-5">
	<ThreeCircles
		outerCircleColor="blue"
		middleCircleColor="black"
		innerCircleColor="grey"
		wrapperClass="center"
	/>
</div>;

/**
 * React Component that sets up the data for the table and the scroll bar
 */
function Leaderboards() {
	const dispatch = useDispatch();

	const dateRange = useSelector(({ leaderboards }: RootState) => leaderboards.dateRange);
	const top5Text = useSelector(({ leaderboards }: RootState) => leaderboards.top5Text);
	const setterData = useSelector(({ leaderboards }: RootState) => leaderboards.setterData) || [];
	const selfgenData = useSelector(({ leaderboards }: RootState) => leaderboards.selfgenData) || [];
	const closerData = useSelector(({ leaderboards }: RootState) => leaderboards.closerData) || [];
	const loading = useSelector(({ leaderboards }: RootState) => leaderboards.loading);
	const setterColumns = useSelector(({ leaderboards }: RootState) => leaderboards.setterColumns);
	const selfgenColumns = useSelector(({ leaderboards }: RootState) => leaderboards.selfgenColumns);
	const closerColumns = useSelector(({ leaderboards }: RootState) => leaderboards.closerColumns);
	const viewType = useSelector(({ leaderboards }: RootState) => leaderboards.viewType);
	const repScorecardModalShown = useSelector(({ leaderboards }: RootState) => leaderboards.repScorecardModalShown);
	const repScorecardData = useSelector(({ leaderboards }: RootState) => leaderboards.repScorecardData);

	useEffect(() => {
		if (dateRange) dispatch(getData(dateRange));
	}, [dateRange]);

	const detailedView = <>
		{ Object.values(closerData).length === 0 &&  Object.values(selfgenData).length === 0 && Object.values(setterData).length === 0 ?
			<h4 className="center text-center text-white mt-5">No data found</h4> :
			<></>
		}
		{ Object.values(closerData).length > 0 ?
			<TableWrapper header="Closer Leaderboard">
				{ top5Text?.closer ? <Container className="mt-3">
					<Marquee className="marquee-scroller" speed={35} gradient={false} pauseOnHover={true} >
						<p>{top5Text.closer}</p>
					</Marquee>
				</Container> : <></>
				}
				<Table data={closerData} columns={closerColumns} responsive stickyHeader />
			</TableWrapper>
			: <></>
		}
		{ Object.values(selfgenData).length > 0 ?
			<TableWrapper header="Self-Gen Leaderboard">
				{ top5Text?.selfgen ? <Container className="mt-3">
					<Marquee className="marquee-scroller" speed={35} gradient={false} pauseOnHover={true} >
						<p>{top5Text.selfgen}</p>
					</Marquee>
				</Container> : <></>
				}
				<Table data={selfgenData} columns={selfgenColumns} responsive stickyHeader />
			</TableWrapper>
			: <></>
		}
		{ Object.values(setterData).length > 0 ?
			<TableWrapper header="Setter Leaderboard">
				{ top5Text?.setter ? <Container className="mt-3">
					<Marquee className="marquee-scroller" speed={35} gradient={false} pauseOnHover={true} >
						<p>{top5Text.setter}</p>
					</Marquee>
				</Container> : <></>
				}
				<Table data={setterData} columns={setterColumns} responsive stickyHeader />
			</TableWrapper>
			: <></>
		}
	</>;

	const PlaceholderUserImage = () => {
		return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
			<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
			<path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
		</svg>;
	};

	const onUserCardClick = (user) => {
		dispatch(setRepScorecardData(user));
		dispatch(setRepScorecardModalShown(true));
	};

	const LargeUserCard = ({ user }) => {
		return <Card onClick={() => onUserCardClick(user)}>
			<Card.Title className="simple-view-large-card-power-rank">{`#${user.detail?.power_rank?.display_value}`}</Card.Title>
			<PlaceholderUserImage />
			<Card.Body>
				<Card.Text>
					<ul>
						<li>{user.name}</li>
						<li>{user.market}</li>
						<li className="simple-view-large-card-power-score">{user.detail?.power_score?.display_value}</li>
					</ul>
				</Card.Text>
			</Card.Body>
		</Card>;
	};

	const SmallUserCard = ({ user }) => {
		return <Card onClick={() => onUserCardClick(user)}>
			<div className="leaderboard-simple-card-name">
				<PlaceholderUserImage />
				<div>
					<div>{user.name}</div>
					<div>{user.market}</div>
				</div>
			</div>
			<div className="leaderboard-simple-card-score">{user.detail?.power_score?.display_value}</div>
			<div className="leaderboard-simple-card-rank">{`#${user.detail?.power_rank?.display_value}`}</div>
		</Card>;
	};

	const SimpleViewRoleSection = ({ data, title }) => {
		return <>
			<h3 className="mt-4">{title}</h3>
			<div className="leaderboard-top3-container">
				{ data?.slice(0, 3).map(setter => <LargeUserCard key={setter.name} user={setter}/>) }
			</div>
			<Container className="leaderboard-simple-list-container">
				{ data?.slice(3, 10).map(setter => <SmallUserCard key={setter.name} user={setter}/>) }
			</Container>
		</>;
	};

	const simpleView = <div className="leaderboard-simple-view">
		{ setterData.length ? <SimpleViewRoleSection data={setterData} title="Setters" /> : <></> }
		{ closerData.length ? <SimpleViewRoleSection data={closerData} title="Closers" /> : <></> }
		{ selfgenData.length ? <SimpleViewRoleSection data={selfgenData} title="Self-Gen" /> : <></> }
	</div>;

	const { startDate, endDate } = dateRange || { startDate: "", endDate: "" };

	return (
		<Container className="leaderboards">
			<SunsetBar/>
			<WhenAuthorized permission={UserPermissions.leaderboard}>
				<>
					<Modal
						isShown={repScorecardModalShown}
						hideModal={() => {
							dispatch(setRepScorecardModalShown(false));
						}}
						body={<Scorecard user={repScorecardData} />}
						heading="Rep Scorecard"
						addlProps={{ size: "lg" }}
					/>
					<h2 style={{ margin: "40px 0", textAlign: "center" }}>COMPANY LEADERBOARDS</h2>
					<div className="leaderboards-container">
						<div className="leaderboard-topbar-container">
							{ dateRange?.endDate && dateRange?.startDate ?
								<>
									<div className="leaderboard-toggle-container">
										<Toggle options={["Detailed", "Simple"]} onChange={() => dispatch(setViewType(viewType === "simple" ? "detailed" : "simple"))} />
									</div>
									<TopBar
										setStartDate={(d) => dispatch(setDateRange({ startDate: d, endDate }))}
										setEndDate={(d) => dispatch(setDateRange({ startDate, endDate: d }))}
										startDate={startDate}
										endDate={endDate}
										minDate={"2022-1-1"}
										setDateRange={(dateRange) => dispatch(setDateRange(dateRange))}
										disabled={loading}
									/>
								</>
								: <></>
							}
						</div>
						{loading ?
							<div>{loadingCircles}</div>
							:
							viewType === "simple" ? simpleView : detailedView
						}
					</div>
				</>
			</WhenAuthorized>
		</Container>

	);
}
export default Leaderboards;
