import React from "react";
import "./SunsetBar.sass";

const SunsetBar = () => {
	return <div>
		<div className="sunset-bar">
			<p>You are looking at outdated data. Please reach out to your manager if you need more information about where to find up to date information. Please be advised this page will be going away November 15th.</p>
		</div>
	</div>;
};

export default SunsetBar;
