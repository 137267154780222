import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import WhenAuthorized from "../Components/WhenAuthorized";
import { UserPermissions } from "../enums/UserPermissions";
import CreateCustomer from "../Components/CreateCustomer/CreateCustomer";
import Modal from "../Components/Modal/Modal";
import { SelectInput, TextInput } from "../Components/Form";
import Button from "react-bootstrap/Button";
import filterList from "../utils/filterList";
import "./styles/CustomerPipeline.sass";
import { getRepList } from "../redux/actions/general";
import { useDispatch, useSelector } from "react-redux";
import ExportData from "../Components/ExportData/ExportData";
import {
	setFilteredCustomerList,
	setCurrentRep,
	setIsCreateCustomerModalShown,
	setIsExportPageShown,
	getCustomers,
} from "../redux/actions/customerPipeline";
import { getProfile } from "../redux/actions/profileActions";
import Heading from "../Components/Heading/Heading";
import CustomerList from "../Components/CustomerList/CustomerList";
import Toggle from "../Components/Toggle/Toggle";
import {
	setViewType
} from "../redux/actions/customerPipeline";
import SunsetBar from "../Components/SunsetBar/Sunsetbar";

const company_vals: Array<SelectOption> = [];

function clearArray<T>(array: Array<T>) {
	while (array.length > 0) {
		array.pop();
	}
}

const CustomerPipeline = () => {
	const repList = useSelector(({ general }: RootState) => general.repList);
	const currentRep = useSelector(({ customerPipeline }: RootState) => customerPipeline.currentRep);
	const customerList = useSelector(({ customerPipeline }: RootState) => customerPipeline.customerList);
	const filteredCustomerList = useSelector(({ customerPipeline }: RootState) => customerPipeline.filteredCustomerList);
	const loadingCustomers = useSelector(({ customerPipeline }: RootState) => customerPipeline.loadingCustomers);
	const isCreateCustomerModalShown = useSelector(({ customerPipeline }: RootState) => customerPipeline.isCreateCustomerModalShown);
	const isExportPageShown = useSelector(({ customerPipeline }: RootState) => customerPipeline.isExportPageShown);
	const profile_details = useSelector(({ profile }: RootState) => profile.profile);
	const dispatch = useDispatch();

	const toggleOptions = [
		<svg key="1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
			<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
		</svg>,
		<svg key="2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-view-stacked" viewBox="0 0 16 16">
			<path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z" />
		</svg>
	];

	const viewTypes = ["list", "grid"];

	useEffect(() => {
		clearArray(company_vals);
		let company_exists = false;
		if (profile_details?.company) {
			for (let i = 0; i < company_vals.length; i++) {
				if (company_vals[i].value == profile_details.company) {
					company_exists = true;
				}
			}
			if (company_exists === false) {
				company_vals.push({ key: (company_vals.length + 100).toString(), value: profile_details.company, label: profile_details.company });
			}
		}
		if (profile_details?.has_access_to_companies) {
			profile_details?.has_access_to_companies?.map((company: string, index: number) => {
				if (!company_vals.includes({ key: index.toString(), value: company, label: company })) {
					company_vals.push({ key: index.toString(), value: company, label: company });
				}
				else {
					company_vals.push({ key: (index + 1).toString(), value: company, label: company });
				}
			});
		}
	}, [profile_details]);

	const handleToggleType = (typeIndex: number) => {
		dispatch(setViewType(viewTypes[typeIndex]));
	};

	useEffect(() => {
		if (!repList) {
			dispatch(getRepList());
		}
	}, []);

	useEffect(() => {
		if (!currentRep) {
			dispatch(setCurrentRep(localStorage.getItem("email") || ""));
		}
	}, []);

	useEffect(() => {
		if (currentRep) {
			dispatch(getCustomers(currentRep));
		}
	}, [currentRep]);

	useEffect(() => {
		if (profile_details === undefined) {
			dispatch(getProfile());
		}
	}, [profile_details]);

	const customerFilterKeys = ["name", "status_name", "email", "state"];
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (customerList) {
			dispatch(setFilteredCustomerList(
				filterList<Customer>(
					customerList.map(cus => { cus.name = `${cus.first_name ? `${cus.first_name} ` : ""}${cus.last_name ? cus.last_name : ""}`; return cus; }),
					e.target.value.toUpperCase(),
					customerFilterKeys
				)
			));
		}
	};

	return (
		<WhenAuthorized permission={UserPermissions.customer}>
			<>
				<SunsetBar/>
				<Modal
					isShown={isCreateCustomerModalShown}
					hideModal={() => {
						dispatch(setIsCreateCustomerModalShown(false));
					}}
					body={<CreateCustomer customerCreated={() => dispatch(setIsCreateCustomerModalShown(false))} />}
					heading="Create JobNimbus Customer"
					addlProps={{ size: "lg" }}
				/>
				<Modal
					isShown={isExportPageShown}
					hideModal={() => {
						dispatch(setIsExportPageShown(false));
					}}
					body={<ExportData company_vals={company_vals} />}
					heading="Export Company Data"
					addlProps={{ size: "lg" }}
				/>
				<Container>
					<Heading>Customer Pipeline</Heading>
					<div className="customer-toggle-button-container container">
						<WhenAuthorized permission={UserPermissions.createCustomer}>
							<Button variant="primary"
								onClick={() => {
									dispatch(setIsCreateCustomerModalShown(true));
								}}
							>
								Create Customer
							</Button>
						</WhenAuthorized>
					</div>
					<div>
						<WhenAuthorized permission={UserPermissions.portal_export_caliber}>
							<div className="export-button-container">
								<Button variant="primary"
									onClick={() => {
										dispatch(setIsExportPageShown(true));
									}}
								>
									Export Company Data
								</Button>
							</div>
						</WhenAuthorized>
					</div>
					{customerList || repList ?
						<div className="customer-search-wrapper container">
							<div className="customer-search-container">
								<div className="customer-select-container">
									<SelectInput
										field={{
											controlId: "repSelect",
											label: "Show for Sales Agent",
											dropdownData: (repList || []).map(rep => ({ key: rep.email, value: rep.email, label: rep.name })).filter(rep => !!rep.label),
											value: currentRep,
											onChange: (e: React.ChangeEvent<HTMLSelectElement>) => dispatch(setCurrentRep(e.target.value))
										}}
									/>
									<TextInput
										field={{
											controlId: "customerFilterString",
											label: "Search for Customer",
											onChange: handleChange,
											placeholder: "Search for Customer"
										}}
									/>
								</div>
								<div className="customer-list-toggle-container">
									<Toggle options={toggleOptions} onChange={handleToggleType} />
								</div>
							</div>
						</div>
						:
						""
					}
					{loadingCustomers ? <div className="customer-loading-spinner">
						<Spinner animation="border" variant="info" />
					</div> :
						filteredCustomerList?.length ?
							<CustomerList customerList={filteredCustomerList} source="customerPipeline" />
							: <Card>
								<Card.Body>There are no Customers associated with this Sales Rep</Card.Body>
							</Card>
					}
				</Container>
			</>
		</WhenAuthorized >
	);
};

export default CustomerPipeline;
