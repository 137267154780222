import React from "react";
import Logo from "../../Images/Image from iOS.jpg";
import "./Header.sass";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";
import { useAuth0 } from "@auth0/auth0-react";
import Dropdown from "react-bootstrap/Dropdown";

/**
 * React component for the Header for the Webapp
 *
 * @returns JSX Element for the Header for the whole Webapp
 */
function Header() {
	const {
		logout: auth0Logout,
	} = useAuth0();

	const logout = () => {
		localStorage.clear();
		auth0Logout({
			logoutParams: {
				returnTo: window.location.origin
			}
		});
	};

	const defaultUserIcon = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-person-circle default-user-icon" viewBox="0 0 16 16">
		<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
		<path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
	</svg>;

	const userIconUrl = localStorage?.getItem("logo");

	const userIcon = userIconUrl ? <img src={userIconUrl} alt="Profile Pic" /> : defaultUserIcon;

	return (
		<Navbar expand="lg" sticky="top">
			<Container>
				<Navbar.Brand href="/">
					<img src={Logo} alt="logoimage" width={150} height={85} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<WhenAuthorized permission={UserPermissions.leaderboard}>
							<Nav.Link href="/leaderboard">Leaderboards</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.commission}>
							<Nav.Link href="/commission">Commission</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.commissionCalculation}>
							<Nav.Link href="/commission-calculation">Commission Calculation</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.redlineInsert}>
							<Nav.Link href="/redline">Redline</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.user}>
							<Nav.Link href="/users">Users</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.customer}>
							<Nav.Link href="/customer">Customer Pipeline</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.company}>
							<Nav.Link href="/company-pipeline">Company Pipeline</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.market}>
							<Nav.Link href="/market">Market Pipeline</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.agreement}>
							<Nav.Link href="/agreement">Project Agreement</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.validator}>
							<Nav.Link href="/validator">Data Validation</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.performanceAuditor}>
							<Nav.Link href="/performance-auditor">Performance Auditor</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.reports}>
							<Nav.Link href="/reports">Reports</Nav.Link>
						</WhenAuthorized>
						<WhenAuthorized permission={UserPermissions.view_pricing_page}>
							<Nav.Link href="/pricing">Pricing</Nav.Link>
						</WhenAuthorized>
						<Nav.Link href="/resource">Resources</Nav.Link>
						<Nav.Link href="https://infinitysolarusa.atlassian.net/servicedesk/customer/portal/2/group/9" target="_blank">Report an issue 🐛</Nav.Link>
					</Nav>
					<Dropdown>
						<Dropdown.Toggle variant="success" id="user-dropdown">
							{userIcon}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item>
								<div className="profile-dropdown-item-container">
									Profile
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-vcard" viewBox="0 0 16 16">
										<path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z" />
										<path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z" />
									</svg>
								</div>
							</Dropdown.Item>
							<Dropdown.Item onClick={logout}>
								<div className="profile-dropdown-item-container">
									Logout
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-left" viewBox="0 0 16 16">
										<path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
										<path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
									</svg>
								</div>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Navbar.Collapse>
			</Container>
		</Navbar >
	);
}

export default Header;
