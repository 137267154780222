import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "../Modal/Modal";
import {
	setIsProjectsDetailModalShown,
	getAuroraDesigns,
} from "../../redux/actions/auroraProjects";
import AuroraProjects from "../CustomerAuroraProjects/CustomerAuroraProjects";
import "./CustomerDetails.sass";
import apiCall from "../../utils/api";
import { TextArea } from "../Form";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setOriginalHomeowner } from "../../redux/actions/installAgreement";
import { useNavigate } from "react-router-dom";
import { getCustomers, setCurrentCustomer, setIsCustomerDetailModalShown } from "../../redux/actions/customerPipeline";
import { setIsCustomerListModalShown } from "../../redux/actions/reports";
import CreateCustomer from "../CreateCustomer/CreateCustomer";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";
import Autosuggest from "react-autosuggest";
import DOMPurify from "dompurify";
import { Buffer } from "buffer";
import SiteSurveyScheduler from "../SiteSurveyScheduler/SiteSurveyScheduler";
import { setIsSiteSurveyModalShown } from "../../redux/actions/siteSurveyScheduler";
import { setIsCloserAppointmentModalShown } from "../../redux/actions/closerAppointmentScheduler";
import CloserAppointmentScheduler from "../CloserAppointmentScheduler/CloserAppointmentScheduler";

interface UserObject {
	user_id: string;
	email: string;
	name: string;
	first_name: string;
	last_name: string;
}

const injectLinksInNote = (note: string, users: Array<UserObject>) => {
	/*
	 * For each user in the list, replace the @name with an anchor that
	 * looks like this: <a href="/users/:user_id">:name</a>
	 * */
	let newNote = note;
	users.forEach(user => {
		const regex = new RegExp(`@${user.name}`, "g");
		newNote = newNote.replace(regex, `<a href="/users/${user.user_id}">${user.name}</a>`);
	});
	return newNote;
};


const getCursorXY = (input, selectionPoint) => {
	/*
	 * This function calculates the x and y position of the cursor
	 * input is the textarea element
	 *
	 * It does so by leveraging a dummy element that will have the same text
	 * rendering as the input element up until selectionPoint
	*/
	const {
		offsetLeft: inputX,
		offsetTop: inputY,
	} = input;

	const div = document.createElement("div");
	const copyStyle = getComputedStyle(input);
	for (const prop of copyStyle) {
		div.style[prop] = copyStyle[prop];
	}
	// Count how many newlines are present in the text up to selection point
	const lineBreaks = (input.elements[0].value.slice(0, selectionPoint).match(/\n/g) || []).length;
	// Account for single-line inputs
	const swap = ".";
	const inputValue = input.tagName === "INPUT" ? input.value.replace(/ /g, swap) : input.elements[0].value;

	// Get the height of the font for adding height to the dummy div
	const fontSize = parseInt(copyStyle.fontSize);

	// Retrieve the text content
	const textContent = inputValue.substr(0, selectionPoint);
	div.textContent = textContent;

	// Both of these are for future proofing, but are not currently used
	if (input.tagName === "TEXTAREA") div.style.height = "auto";
	if (input.tagName === "INPUT") div.style.width = "auto";

	// Create a span to obtain caret position
	const span = document.createElement("span");

	// Give the span the textContent of remaining content so that the recreated dummy element is as close as possible
	span.textContent = inputValue.substr(selectionPoint) || ".";
	// Append the span marker to the div, and that to the body
	div.appendChild(span);
	document.body.appendChild(div);

	// Get the span's position, this is the caret position top and left relative to the input
	const { offsetLeft: spanX, offsetTop: spanY } = span;

	// Now that we have the caret position, remove the dummy span
	document.body.removeChild(div);

	// Get the max hieght between the input and the span, so as to not overflow the input
	const maxHeight = Math.min(inputY + input.elements[0].offsetHeight, spanY + (lineBreaks * fontSize) + fontSize);
	return {
		x: inputX + spanX,
		y: maxHeight,
	};
};

function textToBase64(text) {
	let result = encodeURIComponent(text).replace(/%([0-9A-F]{2})/g, function (match, p1) {
		const charCode = parseInt(p1, 16);
		return String.fromCharCode(charCode);
	});
	result = Buffer.from(result).toString("base64");
	return result;
}

const Note = ({ data }: { data: JobNimbusNote }) => {
	// We need to sanitize the HTML to prevent XSS attacks, then convert the HTML to a React element
	// This is expressly just because we want the comment to match with JobNimbus
	const sanitizedNote = DOMPurify.sanitize(data.note);
	const note = <div dangerouslySetInnerHTML={{ __html: sanitizedNote }} />;
	return <tr className="jobnimbus-note-container">
		<td className="jobnimbus-note-source">
			<p>{data.created_by_name}</p>
			<p>{data.date_created}</p>
			<p>{data.record_type_name}</p>
		</td>
		<td>{note}</td>
	</tr>;
};

const NotesTable = ({ notes }: { notes: Array<JobNimbusNote> }) =>
	<Table bordered className="jobnimbus-activity-table" responsive>
		<tbody>
			{notes.map(note => <Note data={note} key={note.jobnimbus_activity_id} />)}
		</tbody>
	</Table>;

const NoteInput = ({ onSubmit, onChange, submittingNote, textAreaField }) =>
	<div className="customer-addnote-container">
		<Form onSubmit={onSubmit} onChange={onChange}>
			<TextArea field={textAreaField} />
			<div className="customer-add-note-button">
				<Button
					type="submit"
					disabled={submittingNote}
					className="mt-3"
				>
					Submit
				</Button>
			</div>
		</Form>
	</div>;

const CustomerDetails = ({ customer, source }: { customer: Customer, source: string }) => {
	const [notes, setNotes] = useState<Array<JobNimbusNote>>([]);
	const [suggestionPrompt, setSuggestionPrompt] = useState<string>("");
	const [suggestions, setSuggestions] = useState<Array<UserObject>>([]);
	const [noteValue, setNoteValue] = useState<string>("");
	const [loadingNotes, setLoadingNotes] = useState<boolean>(false);
	const [submittingNote, setSubmittingNote] = useState<boolean>(false);
	const [retrieveNotes, setRetrieveNotes] = useState<boolean>(true);
	const [editCustomerModalShown, setEditCustomerModalShown] = useState<boolean>(false);
	const currentRep = useSelector(({ customerPipeline }: RootState) => customerPipeline.currentRep);
	const customerList = useSelector(({ customerPipeline }: RootState) => customerPipeline.customerList);
	const [taggedUsers, setTaggedUsers] = useState<Array<UserObject>>([]);
	const isSiteSurveyModalShown = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler?.isSiteSurveyModalShown);
	const isCloserAppointmentModalShown = useSelector(({ closerAppointmentScheduler }: RootState) => closerAppointmentScheduler?.isCloserAppointmentModalShown);

	const navigate = useNavigate();

	useEffect(() => {
		if (retrieveNotes) {
			setLoadingNotes(true);
			apiCall({ url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/activity?id=${customer.jobnimbus_id}` })
				.then(res => {
					setNotes(res); setLoadingNotes(false);
				});
		}
		setRetrieveNotes(false);
	}, [retrieveNotes]);

	// Updating customer triggers retrieval of new customerList with updated customer
	// This useEffect will update the current customer in the redux store
	// If user updates customer's setter or closer to no longer include themselves, they will no longer see the customer, so hide modal
	useEffect(() => {
		const updatedCustomer = customerList?.find(cus => cus.jobnimbus_id === customer.jobnimbus_id);
		if (updatedCustomer) {
			dispatch(setCurrentCustomer(customerList?.find(cus => cus.jobnimbus_id === customer.jobnimbus_id) as Customer));
		} else {
			if (source === "customerPipeline") {
				dispatch(setIsCustomerDetailModalShown(false));
			}
		}
	}, [customerList]);
	useEffect(() => {
		if (suggestionPrompt && suggestionPrompt.length > 2) {
			apiCall({ url: `${process.env.REACT_APP_API_SERVICE}/permissions/user/search?search=${suggestionPrompt}` })
				.then(res => {
					setSuggestions(res);
				});
		} else {
			setSuggestions([]);
		}
		setRetrieveNotes(false);
	}, [suggestionPrompt]);

	const DetailItem = ({ field, value }: { field: string, value: string | null }) => <p style={{ marginBottom: 0 }}><b>{field}:</b> {value}</p>;

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const injectedValue = injectLinksInNote(noteValue, taggedUsers);
		setSubmittingNote(true);
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/activity`,
			method: "POST",
			body: {
				note: textToBase64(injectedValue),
				id: customer.jobnimbus_id
			}
		}).then(() => { setRetrieveNotes(true); setSubmittingNote(false); setNoteValue(""); })
			.catch((e) => { console.log(e); setSubmittingNote(false); });
	};

	const onSuggestionSelected = (e: React.FormEvent<HTMLFormElement>, { suggestionValue }: { suggestionValue: string }) => {
		// Retrieve all the characters up till the most recent @ character
		let content = noteValue.slice(0, noteValue.lastIndexOf("@"));
		// content = content.concat(`<a href=''>@${suggestionValue}</a>`);
		content = content.concat(`@${suggestionValue}`);

		// Add the user suggestion to the tagged users array
		const user = suggestions.find(user => user.name === suggestionValue);
		if (user) {
			setTaggedUsers([...taggedUsers, user]);
		}
		setNoteValue(content);
		setSuggestions([]);

		// Retrieve the location of the Autosuggest component
		const autosuggestContainer = document.getElementsByClassName("react-autosuggest__container") as HTMLCollectionOf<HTMLElement>;

		// Set the position of the autosuggest container
		if (autosuggestContainer.length != 0) {
			autosuggestContainer[0].style.visibility = "hidden";
		}

		// TODO: After the value has been added, set the focus back to the form input/textarea
	};

	const onAutoSuggestChange = (e: React.ChangeEvent<HTMLFormElement>) => {
		// If the target type is empty, return nothing
		setSuggestionPrompt(e.target.value);
		return;
	};

	const onChange = (e: React.ChangeEvent<HTMLFormElement>) => {
		// Split on newlines and spaces
		const { currentTarget: input } = e;
		const { y: startTop, x: startLeft } = getCursorXY(input, e.currentTarget.selectionStart);
		// then get the ending top and left using selectionEnd
		const { y: endTop, x: endLeft } = getCursorXY(input, e.currentTarget.selectionEnd);
		const endPoint = startTop !== endTop ? e.currentTarget.offsetLeft + (e.currentTarget.offsetWidth - parseInt(e.currentTarget.paddingRight, 10)) : endLeft;
		// we want the marker to show above the selection and in the middle of the selection so start point plus halve the endpoint minus the start point
		const newLeft = startLeft + ((endPoint - startLeft) / 2);

		// If the last word typed starts with @, then we should start searching for a user
		let visibility = "hidden";
		if (e.target.value.split(/[\n\s]/).pop().startsWith("@")) {
			// Retrieve all the text since the last @ symbol
			const tag = e.target.value.split("@").pop();
			setSuggestionPrompt(tag);
			visibility = "visible";
		}
		// Retrieve the location of the Autosuggest component
		const autosuggestContainer = document.getElementsByClassName("react-autosuggest__container") as HTMLCollectionOf<HTMLElement>;

		// Set the position of the autosuggest container
		if (autosuggestContainer.length != 0) {
			autosuggestContainer[0].style.position = "absolute";
			autosuggestContainer[0].style.top = `${startTop - e.currentTarget.scrollTop}px`;
			autosuggestContainer[0].style.left = `${newLeft - e.currentTarget.scrollLeft}px`;
			autosuggestContainer[0].style.visibility = visibility;
		}
	};

	const textAreaField = {
		value: noteValue,
		onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => setNoteValue(e.target.value),
		as: "textarea",
		label: <b style={{ fontSize: "18px" }}>Add Note</b>,
		rows: 3,
		controlClasses: "mb-3",
		disabled: submittingNote
	};

	const {
		first_name,
		last_name,
		email,
		phone,
		address,
		state,
		zip,
		utility_company,
		setter,
		closer,
		selfgen,
		setter_2,
		closer_2,
		selfgen_2,
		customer_id
	} = customer;

	const dispatch = useDispatch();

	const onCreateInstallAgreementPortal = () => {
		const mapStateAbbreviation = {
			AZ: "Arizona",
			TX: "Texas",
			WA: "Washington",
			OR: "Oregon",
			ID: "Idaho",
			NM: "New Mexico",
			NC: "North Carolina",
		};

		const formData = {
			cancellation_verbiage: "site_survey",
			setter: "other",
			"homeowner": `${first_name} ${last_name}`,
			"email": email,
			"phone": phone,
			"street": address,
			"state": state ? mapStateAbbreviation[state] : "",
			"zip": zip,
			"utility_company": utility_company?.toUpperCase() === "PGE" ? "Portland General Electric" : utility_company,
			"customer_id": customer_id,
		};

		dispatch(setIsCustomerDetailModalShown(false));
		dispatch(setIsCustomerListModalShown(false));
		if (formData.customer_id != null) {
			dispatch(setOriginalHomeowner(`${first_name} ${last_name}`));
		}
		dispatch(setForm(formData));
		navigate("/agreement");
	};

	const onEditCustomer = () => {
		setEditCustomerModalShown(true);
	};

	const adaptCustomer = (customer: Customer) => ({
		...customer,
		name: `${customer.first_name ? `${customer.first_name} ` : ""}${customer.last_name ? customer.last_name : ""}`,
		firstName: customer.first_name,
		lastName: customer.last_name,
		utilityCompany: customer.utility_company,
		jnId: customer.jobnimbus_id,
	});

	const onCustomerCreated = () => {
		setEditCustomerModalShown(false);
		dispatch(getCustomers(currentRep as string));
	};

	const designs = useSelector(({ auroraProjects }: RootState) => auroraProjects?.designs);
	const isAuroraProjectDetailModalShown = useSelector(({ auroraProjects }: RootState) => auroraProjects?.isAuroraProjectDetailModalShown);
	const showAuroraProjectDetailModal = () => {
		dispatch(getAuroraDesigns(customer.jobnimbus_id?.toString() || ""));
		dispatch(setIsProjectsDetailModalShown(true));
	};

	return (<>
		<Modal
			isShown={editCustomerModalShown}
			hideModal={() => {
				setEditCustomerModalShown(false);
			}}
			body={<CreateCustomer customerCreated={onCustomerCreated} customer={adaptCustomer(customer)} />}
			heading="Edit JobNimbus Customer"
			addlProps={{ size: "lg" }}
		/>
		<Modal
			isShown={isAuroraProjectDetailModalShown ?? false}
			hideModal={() => {
				dispatch(setIsProjectsDetailModalShown(false));
			}}
			body={designs ? <AuroraProjects auroraProjects={designs} /> : <></>}
			heading=""
			addlProps={{ size: "lg" }}
		/>
		<Modal
			isShown={isSiteSurveyModalShown}
			hideModal={() => {
				dispatch(setIsSiteSurveyModalShown(false));
			}}
			body={<SiteSurveyScheduler customer={customer} />}
			heading=""
			addlProps={{ size: "lg" }}
		/>
		<Modal
			isShown={isCloserAppointmentModalShown}
			hideModal={() => {
				dispatch(setIsCloserAppointmentModalShown(false));
			}}
			body={<CloserAppointmentScheduler customer={customer} />}
			heading=""
			addlProps={{ size: "lg" }}
		/>
		<div>
			<div className="project-create-buttons">
				<WhenAuthorized permission={UserPermissions.agreement}>
					<Button onClick={onCreateInstallAgreementPortal}>
						Portal Project Agreement
					</Button>
				</WhenAuthorized>
				<WhenAuthorized permission={UserPermissions.agreement}>
					<WhenAuthorized permission={UserPermissions.auroraSolarProject}>
						<Button onClick={showAuroraProjectDetailModal}>
							Aurora Project Agreement
						</Button>
					</WhenAuthorized>
				</WhenAuthorized>
				<WhenAuthorized permission={UserPermissions.siteSurvey}>
					<Button onClick={() => dispatch(setIsSiteSurveyModalShown(true))}>
						Schedule Site Survey
					</Button>
				</WhenAuthorized>
				<WhenAuthorized permission={UserPermissions.closerAppointment}>
					<Button onClick={() => dispatch(setIsCloserAppointmentModalShown(true))}>
						Schedule Closer Appointment
					</Button>
				</WhenAuthorized>
				<WhenAuthorized permission={UserPermissions.edit_customer}>
					<Button onClick={onEditCustomer} className="ms-2" title="Edit customer">
						<i className="bi bi-pencil-square"></i>
					</Button>
				</WhenAuthorized>
			</div>
			<div className="customer-detail-modal-header-container">
				<h3>{`${first_name} ${last_name}`}</h3>
			</div>
			<Row>
				<Col md={6} sm={12}>
					<DetailItem field="First Name" value={first_name} />
					<DetailItem field="Last Name" value={last_name} />
					<DetailItem field="Email" value={email} />
					<DetailItem field="Phone" value={phone} />
					<DetailItem field="Address" value={address} />
					<DetailItem field="State" value={state} />
					<DetailItem field="Zip" value={zip} />
					<DetailItem field="Utility Company" value={utility_company} />
				</Col>
				<Col md={6} sm={12}>
					<DetailItem field="Status" value={customer.status_name} />
					<DetailItem field="Days In Status" value={customer.days_in_status} />
					<DetailItem field="Date Status Change" value={customer.date_status_change} />
					<DetailItem field="Install Status" value={customer.customer_install_status} />
					<DetailItem field="Sales Rep" value={customer.closer} />
					<DetailItem field="Total Revenue" value={customer.total_combined_revenue} />
					<DetailItem field="KW" value={customer.kw} />
				</Col>
			</Row>
			<h4 className="mt-4">Assignees</h4>
			<Row>
				<Col md={6} sm={12}>
					<DetailItem field="Setter" value={setter} />
					<DetailItem field="Closer" value={closer} />
					<DetailItem field="Self-Gen" value={selfgen} />
				</Col>
				<Col md={6} sm={12}>
					<DetailItem field="Setter 2" value={setter_2} />
					<DetailItem field="Closer 2" value={closer_2} />
					<DetailItem field="Self-Gen 2" value={selfgen_2} />
				</Col>
			</Row>
			<Autosuggest
				suggestions={suggestions}
				alwaysRenderSuggestions={true}
				onSuggestionSelected={onSuggestionSelected}
				onSuggestionsClearRequested={() => {
					setSuggestions([]);
					setSuggestionPrompt("");
				}}
				onSuggestionsFetchRequested={({ value }) => {
					setSuggestionPrompt(value);
				}}
				getSuggestionValue={(suggestion) => suggestion.name}
				renderSuggestion={suggestion => <span>{suggestion.name}</span>}
				inputProps={{
					placeholder: "Search name..",
					value: suggestionPrompt,
					onChange: onAutoSuggestChange
				}}
				highlightFirstSuggestion={false} />
			<NoteInput
				onSubmit={onSubmit}
				submittingNote={submittingNote}
				textAreaField={textAreaField}
				onChange={onChange}
			/>
			{loadingNotes ?
				<div className="loading-notes-spinner">
					<Spinner variant="info" />
				</div>
				:
				notes.length ? <NotesTable notes={notes} /> : <></>
			}
		</div >
	</>);
};

export default CustomerDetails;

